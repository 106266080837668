const getHref = () => {
  const urls = {
    staging: "https://signup.staging.setmore.com/start-now",
    live: "https://signup.setmore.com/start-now",
  };
  const appMode = process.env.APP_MODE;
  return appMode === "live" ? urls["live"] : urls["staging"];
};

export const replaceHrefInAnswer = (answer) => {
  const href = getHref();
  const regex = /https:\/\/signup\.setmore\.com\/start-now/g;
  if (typeof answer === "string") {
    return answer.replace(regex, href);
  } else if (Array.isArray(answer)) {
    return answer.map((item) => item.replace(regex, href));
  }
  return answer;
};
