export default function CarouselFullWidth({
  data,
  toggleVideo,
  isNewDesign,
  wrapClass = "",
  isNotQuotes,
  isShowPreview = true,
}) {
  const PlayButton = ({ id, isNewDesign }) => {
    return (
      <button
        className="play-btn"
        aria-label="Play Video Button"
        onClick={(e) => {
          let id = e.target.closest(".play-btn").id;
          toggleVideo(id);
        }}
        id={id}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 80 80"
          width="80"
          height="80"
        >
          <g fill="none">
            <circle
              cx="40"
              cy="40"
              r="38"
              fill="#000"
              fillOpacity=".25"
              stroke="#FFF"
              strokeWidth="4"
            ></circle>
            <path
              fill="#FFF"
              d="M32 55.73c0 1.696 1.978 2.622 3.28 1.536l18.876-15.73c.96-.8.96-2.273 0-3.072L35.28 22.734c-1.302-1.086-3.28-.16-3.28 1.536v31.46z"
            ></path>
          </g>
        </svg>

        {isNewDesign && (
          <svg
            className="new-play-btn"
            width="128"
            height="64"
            viewBox="0 0 128 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2"
              y="2"
              width="124"
              height="60"
              rx="30"
              fill="white"
              fillOpacity="0.25"
              stroke="white"
              strokeWidth="4"
            />
            <path
              d="M88 43.8388C88 45.5158 89.9399 46.4481 91.2494 45.4005L106.048 33.5617C107.049 32.7611 107.049 31.2389 106.048 30.4383L91.2494 18.5995C89.9399 17.5519 88 18.4842 88 20.1613V43.8388Z"
              fill="white"
            />
            <path
              d="M29.895 32.322C30.687 32.322 31.257 32.133 31.605 31.755C31.953 31.377 32.127 30.849 32.127 30.171C32.127 29.871 32.082 29.598 31.992 29.352C31.902 29.106 31.764 28.896 31.578 28.722C31.398 28.542 31.167 28.404 30.885 28.308C30.609 28.212 30.279 28.164 29.895 28.164H28.311V32.322H29.895ZM29.895 25.887C30.819 25.887 31.611 25.998 32.271 26.22C32.937 26.436 33.483 26.736 33.909 27.12C34.341 27.504 34.659 27.957 34.863 28.479C35.067 29.001 35.169 29.565 35.169 30.171C35.169 30.825 35.064 31.425 34.854 31.971C34.644 32.517 34.323 32.985 33.891 33.375C33.459 33.765 32.91 34.071 32.244 34.293C31.584 34.509 30.801 34.617 29.895 34.617H28.311V39H25.269V25.887H29.895ZM45.8144 36.57V39H37.9124V25.887H40.9544V36.57H45.8144ZM55.4994 33.987L54.2844 30.396C54.1944 30.168 54.0984 29.901 53.9964 29.595C53.8944 29.283 53.7924 28.947 53.6904 28.587C53.6004 28.953 53.5044 29.292 53.4024 29.604C53.3004 29.916 53.2044 30.186 53.1144 30.414L51.9084 33.987H55.4994ZM60.3324 39H57.9744C57.7104 39 57.4974 38.94 57.3354 38.82C57.1734 38.694 57.0504 38.535 56.9664 38.343L56.1924 36.057H51.2064L50.4324 38.343C50.3664 38.511 50.2464 38.664 50.0724 38.802C49.9044 38.934 49.6944 39 49.4424 39H47.0664L52.1424 25.887H55.2564L60.3324 39ZM67.4244 34.014V39H64.3824V34.014L59.6304 25.887H62.3124C62.5764 25.887 62.7834 25.95 62.9334 26.076C63.0894 26.196 63.2184 26.352 63.3204 26.544L65.1744 30.36C65.3304 30.66 65.4714 30.942 65.5974 31.206C65.7234 31.464 65.8344 31.719 65.9304 31.971C66.0204 31.713 66.1224 31.455 66.2364 31.197C66.3564 30.933 66.4944 30.654 66.6504 30.36L68.4864 26.544C68.5284 26.466 68.5794 26.388 68.6394 26.31C68.6994 26.232 68.7684 26.163 68.8464 26.103C68.9304 26.037 69.0234 25.986 69.1254 25.95C69.2334 25.908 69.3504 25.887 69.4764 25.887H72.1764L67.4244 34.014Z"
              fill="white"
            />
          </svg>
        )}
      </button>
    );
  };

  const Video = ({ id, src, poster, isNewDesign }) => (
    <div className="video-highlight lg:mt-0">
      <a className="video-timg carousel-video-cover" data-id={id}>
        <video
          data-id="2Fc9dCLlVbk"
          muted
          loop
          poster={poster}
          preload="none"
          playsInline
          className="pointer"
        >
          {!isNewDesign && isShowPreview && (
            <source src={src} type="video/mp4" />
          )}
        </video>
        <PlayButton id={id} isNewDesign={isNewDesign} />
      </a>
      <style jsx>{`
        .video-highlight {
          margin-bottom: -10px;
        }
      `}</style>
    </div>
  );

  const VideoCards = ({ data, value, isNewDesign }) => {
    const commonProps = {
      className: `${
        isNewDesign ? "long-carousel-item" : "carousel-item"
      } lg:mr-5 md:mr-2.5`,
      "data-id": value,
      onClick: (e) => {
        toggleVideo(data.video.id);
      },
    };

    return (
      <li {...commonProps}>
        <Video
          id={data.video.id}
          poster={data.video.poster}
          src={data.video.src}
          isNewDesign={isNewDesign}
        />
        <div className="descp pr-3 pt-3 pl-3 pb-4">
          {!isNewDesign ? (
            <>
              <h3
                className="mb-2.5 text-smx fw-400"
                dangerouslySetInnerHTML={{ __html: data.heading }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: isNotQuotes ? data.desc : `“${data.desc}”`,
                }}
              />
              {data.hasOwnProperty("readMore") && (
                <a className="text-brandcolor" href={data.readMore.href}>
                  {data.readMore.text}
                </a>
              )}
            </>
          ) : (
            <>
              <p
                className="mb-2.5 text-smx"
                dangerouslySetInnerHTML={{ __html: data.personName }}
              />
              <h3 dangerouslySetInnerHTML={{ __html: data.heading }} />
            </>
          )}
        </div>
        <style>{`
          .long-carousel {
            width: 2800px;
            transform: translate(-165px);
            transition: all 1s;
          }
          @media (min-width: 1601px) {
            .long-carousel {
              width: 100%;
              justify-content: center;
              transform: none;
            }
          }
          @media (min-width: 1500px) and (max-width: 1600px) {
            .long-carousel {
              transform: translate(-75px);
            }
          }
          @media (min-width: 1400px) and (max-width: 1499px) {
            .long-carousel {
              transform: translate(-160px);
            }
          }
          @media (max-width: 1024px) {
            .long-carousel {
              transform: translate(-390px);
            }
          }
          @media (max-width: 991px) {
            .long-carousel {
              transform: translate(-470px);
            }
          }
          @media (min-width: 360px) and (max-width: 424px) {
            .long-carousel {
              transform: translate(-266px);
            }
          }
          @media (min-width: 425px) and (max-width: 575px) {
            .long-carousel {
              transform: translate(-246px);
            }
          }
          .long-carousel-item {
            padding: 4px;
            max-width: calc((100% - 120px) / 3);
            width: 100%;
            border-radius: 8px;
            box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1), 0 4px 8px 0 rgba(48, 58, 71, 0.14);
            background-color: #fff;
          }
          @media (min-width: 1601px) {
            .long-carousel-item {
              width: 306px;
              margin-right: 12px !important;
            }
            .long-carousel-item .descp {
              padding: 20px 12px !important;
              width: 300px !important;
            }
            .long-carousel-item .video-highlight {
              width: 298px;
              height: auto;
            }
            .long-carousel-item .video-highlight .video-timg {
              width: inherit;
              height: auto;
            }
            .long-carousel-item .video-highlight .video-timg video {
              width: inherit !important;
            }
          }
          @media (max-width: 991px) {
            .long-carousel-item {
              margin-right: 20px !important;
            }
          }
          .long-carousel-item h3 {
            font-size: 24px;
            line-height: 32px;
            color: #303a47;
            text-transform: capitalize;
          }
          .long-carousel-item p {
            max-width: 288px;
            line-height: 24px;
            color: #556270;
          }
          .long-carousel-item p span {
            color: #303a47;
          }
          @media (min-width: 640px) and (max-width: 991px) {
            .long-carousel-item {
              width: 560px;
            }
            .long-carousel-item .video-highlight video {
              width: 560px;
            }
            .long-carousel-item .descp {
              width: 560px;
            }
          }
          @media (max-width: 575px) {
            .long-carousel-item {
              width: 290px;
            }
            .long-carousel-item .video-highlight .video-timg video {
              width: 290px !important;
            }
            .long-carousel-item .descp {
              width: 290px !important;
            }
            .long-carousel-item h3 {
              font-size: 20px;
              line-height: 28px;
            }
          }
        `}</style>
      </li>
    );
  };

  const carouselClass = !isNewDesign ? "carousel" : "long-carousel";

  return (
    <>
      <article className={`carousel-cont ${wrapClass}`}>
        <ul className={`fx ${carouselClass}`}>
          {data.map((item, index) => (
            <VideoCards data={item} key={index} isNewDesign={isNewDesign} />
          ))}
          <VideoCards data={data[2]} isNewDesign={isNewDesign} />
        </ul>
      </article>
    </>
  );
}
